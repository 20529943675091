export const solutionTemplate = {
  solutionId: null,
  sequence: null,
  scheduleStartDate: null,
  simulated: false,
  overtimes: [],
  leavetimes: [],
  errorMessages: [],
  projectId: null
}
