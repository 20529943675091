<template>
  <div class="header">
    <h3 class="header__title">
      {{ $t('schedule.simulation.chooseJobs') }}
      <job-status-legend :job-states="jobStates" />
    </h3>
    <slot name="filter" />
  </div>
</template>

<script>
import JobStatusLegend from '@/schedule/components/JobStatusLegend.vue'
import { JOB_STATUS } from '@/schedule/utils/enum'

export default {
  name: 'JobsHeader',
  components: {
    JobStatusLegend
  },
  computed: {
    jobStates () {
      return [
        JOB_STATUS.SCHEDULED,
        JOB_STATUS.DELAY,
        JOB_STATUS.OVERDUE,
        JOB_STATUS.OVERDUE_DELAY,
        JOB_STATUS.UNSCHEDULED
      ]
    }
  }

}
</script>

<style lang="scss" scoped>
.header {
  margin-bottom: 12px;

  .header {
    &__title {
      display: flex;
      font-size: 20px;
      gap: 8px;
      line-height: 24px;
      margin-bottom: 8px;
      margin-right: 16px;
      margin-top: 0;
      padding: 0 0 0 14px;
      position: relative;

      &::before {
        background: #2ad2e2;
        content: '';
        height: 6px;
        left: 0%;
        position: absolute;
        top: 37.5%;
        width: 6px;
      }
    }

    &__button {
      flex: 1;
      text-align: right;
    }
  }
}
</style>
