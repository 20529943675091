<template>
  <div
    class="dialog"
    @click="closeDialog"
  >
    <div
      class="dialog__inner-box"
      @click.stop
    >
      <div @click="closeDialog">
        <i class="el-icon-close dialog-close" />
      </div>
      <div
        :class="{'hasContent': content}"
        class="dialog__title"
      >
        {{ title }}
      </div>
      <div
        v-if="content"
        class="dialog__description"
      >
        {{ content }}
      </div>
      <div class="dialog__button-block">
        <schedule-button
          v-if="type === 'showCancel'"
          :disabled="isProcessing"
          type="outline"
          class="btn-cancel"
          @click="closeDialog"
        >
          {{ $t('schedule.button.cancel') }}
        </schedule-button>
        <schedule-button
          v-if="type === 'confirm' || type === 'showCancel'"
          :disabled="isProcessing"
          class="btn-confirm"
          @click="confirmBtn"
        >
          <i
            v-if="isProcessing"
            class="el-icon-loading"
          />
          {{ btnText }}
        </schedule-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ScheduleDecideDialog',
  props: {
    title: { type: String, default: '' },
    content: { type: String, default: '' },
    type: { type: String, default: '' },
    isProcessing: { type: Boolean, default: false },
    btnText: {
      type: String,
      default: function () {
        return this.$t('schedule.button.delete')
      }
    }
  },
  methods: {
    closeDialog () {
      this.$emit('closeDialog')
    },
    confirmBtn () {
      this.$emit('confirmBtn')
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__inner-box {
    text-align: left;
  }

  &__title {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 16px;
    text-align: left;
  }

  &__description {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 48px;
  }

  &__button-block {
    display: flex;
    justify-content: flex-end;

    .btn-confirm {
      cursor: pointer;
    }

    .btn-cancel {
      cursor: pointer;
      margin-right: 12px;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}

</style>
