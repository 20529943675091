<template>
  <div class="job-table">
    <div
      v-if="isLoading"
      class="empty-dialog"
    >
      <spinner />
    </div>
    <el-table
      v-else
      ref="multipleTable"
      :data="tableData"
      :empty-text="$t('schedule.base.noData')"
      :row-key="getRowKey"
      class="ss-table"
      style="width: 100%;"
      cell-class-name="schedule-table-cell"
    >
      <el-table-column
        :width="indexColumnWidth"
        type="index"
        align="center"
      >
        <template slot="header">
          <slot name="index-header" />
        </template>
      </el-table-column>
      <el-table-column
        v-for="(col, index) in jobTableHeaderList"
        :key="index"
        min-width="240px"
        :prop="col.title"
        :label="col.name"
        :width="col.width"
        :align="col.align"
      >
        <template #default="scope">
          <!-- 待討論：jobStateDot 因應客製化的處理 -->
          <stateful-job-id
            v-if="index === 0"
            :job-info="scope.row"
          />
          <!-- isDefaultProfile 情況下，部分欄位資料需要轉換  -->
          <!-- 欄位 PRIORITY -->
          <template v-else-if="isDefaultProfile && index === getIndexOfColumnPriority">
            {{ priorityOptions[scope.row.fullData[index] - 1].label }}
          </template>
          <template v-else>
            {{ scope.row.fullData[index] }}
          </template>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('schedule.simulation.table.priority')"
        width="120"
      >
        <template slot-scope="prorityColumn">
          <el-select
            v-model="tableData[prorityColumn.$index].priority"
            @change="onPriorityChanged(tableData[prorityColumn.$index])"
          >
            <el-option
              v-for="item in priorityOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </template>
      </el-table-column>
      <job-check-column
        :is-show-check-all-page-checkbox="isShowCheckAllPageCheckbox"
        :check-all-page="checkAllPages"
        :is-indeterminate="isIndeterminate"
        :key="`${checkSinglePage}${isIndeterminate}`"
        @toggle-check-all-page="toggleCheckAllPages"
      >
        <input
          v-model="checkSinglePage"
          type="checkbox"
        >
      </job-check-column>
    </el-table>
    <el-pagination
      v-if="pagination.totalItems > 0"
      :total="pagination.totalItems"
      :page-size="pagination.itemPerPage"
      :current-page="pagination.currentPage + 1"
      :page-sizes="[20, 50, 100, 200]"
      :disabled="isLoading"
      class="ss-pagination"
      layout="total, sizes, prev, pager, next, jumper"
      @size-change="changeSize"
      @current-change="changePage"
    />
  </div>
</template>

<script>
import StatefulJobId from './StatefulJobId.vue'
import JobCheckColumn from './JobCheckColumn'
import { getJobList } from '@/schedule/API/Job'
import { mapState, mapGetters } from 'vuex'
import { getIndexOfSpecificColumn } from '@/schedule/utils/utils'
import { DEFAULT_JOB_TABLE_COLUMN } from '@/schedule/utils/enum'

export default {
  name: 'JobsTable',
  components: {
    StatefulJobId,
    JobCheckColumn
  },
  props: {
    restrictions: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      isLoading: false,
      tableData: [],
      selectedData: [],
      pagination: {
        currentPage: 0,
        totalPages: 0,
        totalItems: 0,
        itemPerPage: 20
      },
      checkAllPages: false
    }
  },
  computed: {
    ...mapState('simulation', ['scheduledJobs', 'simulationJobs']),
    ...mapState('scheduleSetting', ['scheduleProjectId', 'globalJobStatusRestriction']),
    ...mapGetters('scheduleSetting', ['jobTableHeaderList', 'priorityOptions', 'isDefaultProfile']),
    isIndeterminate () {
      return !this.checkSinglePage && this.tableData.some(item => item.frontendInfo.checked)
    },
    checkSinglePage: {
      get () {
        if (this.tableData.length === 0) return false
        return this.tableData.every(item => item.frontendInfo.checked)
      },
      set (checked) {
        this.tableData.forEach(item => item.frontendInfo.checked = checked)
        this.$store.commit('simulation/setSimulationJobs', this.tableData)
      }
    },
    isShowCheckAllPageCheckbox () {
      return this.pagination.totalItems === 0 || this.pagination.totalItems > this.pagination.itemPerPage
    },
    indexColumnWidth () {
      if (this.tableData.length > 100) {
        return 48
      }
      return 40
    },
    // TODO 之後改由 Profile 讀取
    getIndexOfColumnPriority () {
      return this.getIndexOfSpecificColumn(DEFAULT_JOB_TABLE_COLUMN.PRIORITY.toUpperCase())
    }
  },
  watch: {
    restrictions () {
      this.fetchJobData(0, this.pagination.itemPerPage)
    }
  },
  mounted () {
    this.isLoading = true
    this.fetchJobData(0, this.pagination.itemPerPage)
  },
  methods: {
    fetchJobData (page = 0, size = this.pagination.itemPerPage) {
      this.isLoading = true
      getJobList({ page, size, projectId: this.scheduleProjectId, ...this.restrictions })
        .then(({ data, pagination }) => {
          this.$store.commit('scheduleSetting/setDynamicJobTableColumns', data.headers.map(column => ({ title: column, name: column })))
          this.pagination = pagination
          this.tableData = data.jobs.map(job => {
            // 尋找 store 中是否有存過該筆工單
            const storedJob = this.simulationJobs[job.id]
            return {
              ...job,
              priority: storedJob ? storedJob.priority : job.fullData[this.getIndexOfColumnPriority],
              frontendInfo: {
                checked: storedJob ? storedJob.frontendInfo.checked : job.scheduled
              }
            }
          })
        })
        .catch(() => {})
        .finally(() => {
          this.isLoading = false
        })
    },
    toggleCheckAllPages (checked) {
      this.checkAllPages = checked
      // 將當頁的 job 狀態更新
      this.tableData = this.tableData.map(item => ({
        ...item,
        frontendInfo: { checked: checked }
      }))

      if (checked) {
        getJobList({ fetchAll: true, projectId: this.scheduleProjectId, ...this.restrictions })
          .then(({ jobs }) => {
            const allJobs = jobs.map(job => ({
              ...job,
              frontendInfo: { checked: true }
            }))
            this.$store.commit('simulation/setSimulationJobs', allJobs)
          })
      } else {
        this.$store.commit('simulation/deCheckAllJobs', { jobs: this.simulationJobs, checked: false })
      }
    },
    changeSize (size) {
      this.fetchJobData(0, size)
    },
    changePage (page) {
      if (this.isLoading) return
      this.fetchJobData(page - 1, this.pagination.itemPerPage)
    },
    onPriorityChanged (job) {
      job.frontendInfo.checked = true
      this.$store.commit('simulation/setSimulationJobs', [job])
    },
    getRowKey (row) {
      return row.id
    },
    getIndexOfSpecificColumn
  }
}
</script>

<style lang="scss" scoped>
.job-table {
  display: flex;
  flex: 1;
  flex-direction: column;

  ::v-deep .spinner-block {
    padding: 0;
  }

  ::v-deep .el-input--small .el-input__inner {
    background-color: transparent;
    border: none;
  }

  ::v-deep .el-select .el-input .el-select__caret {
    color: var(--color-white);
    font-weight: bold;
  }

  ::v-deep .el-checkbox__inner {
    background-color: transparent;
  }

  ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: var(--color-theme);
  }

  ::v-deep .el-select > .el-input {
    .el-input__inner {
      background-color: transparent;
      border: none;
      color: #ddd;
      height: 22px;
      line-height: 22px;
      padding: 0;
    }

    .el-input__icon {
      line-height: 22px;
    }
  }

  ::v-deep .ss-table {
    flex: 1;

    .el-table-column--selection .cell {
      padding-left: 10px;
    }
  }

  .empty-dialog {
    align-items: center;
    border-radius: 8px;
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 249px;
    justify-content: center;

    &__text {
      color: var(--color-text-gray);
      font-size: 16px;
      line-height: 20px;
    }
  }
}
</style>
