<template>
  <el-table-column
    :width="isShowCheckAllPageCheckbox ? 60 : 40"
    fixed="right"
  >
    <template slot="header">
      <label
        class="checkbox-label"
        :class="{ indeterminate: isIndeterminate }"
      >
        <slot />
        <div class="checkbox-square" />
      </label>
      <el-tooltip :disabled="!isShowCheckAllPageCheckbox">
        <div
          slot="content"
          class="check-all-dropdown"
        >
          <label class="checkbox-label">
            <input
              v-model="innerCheckAllPage"
              type="checkbox"
            >
            <div class="checkbox-square" />
          </label>
          <span class="check-all-dropdown--label">
            {{ $t('schedule.base.allPageSelect') }}
          </span>
        </div>
        <svg-icon
          v-if="isShowCheckAllPageCheckbox"
          icon-class="dropdown"
          class="dropdown-icon"
        />
      </el-tooltip>
    </template>
    <template slot-scope="scope">
      <label class="checkbox-label">
        <input
          v-model="scope.row.frontendInfo.checked"
          type="checkbox"
          name="selectAll"
          @input="onChange($event, scope.row)"
        >
        <div class="checkbox-square" />
      </label>
    </template>
  </el-table-column>
</template>

<script>
export default {
  name: 'JobCheckColumn',
  props: {
    checkAllPages: {
      type: Boolean,
      default: false
    },
    isShowCheckAllPageCheckbox: {
      type: Boolean,
      default: false
    },
    isIndeterminate: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    innerCheckAllPage: {
      get () {
        return this.checkAllPages
      },
      set (v) {
        this.$emit('toggle-check-all-page', v)
      }
    }
  },
  methods: {
    onChange ({ target: { checked } }, job) {
      this.$store.commit('simulation/setSimulationJobs', [{
        ...job,
        frontendInfo: { checked }
      }])
    }
  }
}
</script>

<style lang="scss" scoped>
.checkbox-label,
.dropdown-icon {
  display: inline-block;
}

.dropdown-icon {
  cursor: pointer;
  margin-left: 8px;
  width: 10px;
}

.check-all-dropdown {
  align-items: center;
  display: flex;

  &--label {
    margin-left: 8px;
  }
}
</style>
