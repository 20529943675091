<template>
  <div class="page page--simulation">
    <div class="page__side-nav">
      <div class="step step--choose-job">
        <div class="step__title">
          STEP.1 {{ $t('schedule.simulation.selectJobs', {job: isYKSchedule ? $t('schedule.simulation.order') : $t('schedule.simulation.job')}) }}
        </div>
        <div class="step__content">
          <spinner
            v-if="isFetchingSelectedOrders"
            size="30"
          />
          <div
            v-else
            class="selected-jobs__info"
            @click="onClickSelectJobStep"
          >
            {{ displaySelectedJobCounter }}
          </div>
        </div>
      </div>
      <div class="step step--choose-solution">
        <div
          v-if="!isFetchingDefaultSetting"
          class="step__title"
        >
          STEP.2 {{ $t('schedule.simulation.solutionSetting') }}
          <schedule-button
            v-show="!(isYKSchedule && solutions.length > 0)"
            class="add-btn"
            type="outline"
            @click="onClickAddSolution"
          >
            <i class="el-icon-plus" />
            {{ $t('schedule.simulation.addSolution') }}
          </schedule-button>
        </div>
        <div class="step__content">
          <solution-card
            v-for="(solution, index) in solutions"
            :key="`solution-card-${solution.sequence}`"
            :solution="solution"
            :solution-failed-judger="isSolutionFailed"
            :current-solution-sequence="editSolutionSequence"
            show-simulation-status
            show-valid-status
            show-remove-icon
            @click-card="editSolution"
            @remove="onClickRemoveSolution(index, solution.solutionId)"
          />
        </div>
      </div>
      <div class="step step--start-simulate">
        <schedule-button
          :disabled="isStartSimulationButtonDisabled"
          @click="validateSolutions"
        >
          {{ $t('schedule.simulation.startSimulation') }}
        </schedule-button>
      </div>
    </div>
    <div
      v-if="editSolutionSequence"
      class="page__main"
    >
      <schedule-tab
        v-if="settingTabs.length > 1"
        class="page__main--switcher"
        :active-tab="activeSettingTab"
        :tabs="settingTabs"
        @switch="onSwitchTab($event)"
      />
      <base-setting
        v-if="activeSettingTab === 'ScheduleBaseSetting'"
        :key="`base-setting-${editSolutionSequence}`"
      />
      <split-merge-setting
        v-if="activeSettingTab === 'ScheduleSplitMergeSetting'"
        :key="`split-merge-setting-${editSolutionSequence}`"
        :solution-sequence="editSolutionSequence"
      />
    </div>
    <div
      v-show="!editSolutionSequence"
      class="page__main"
    >
      <h2 class="header__title">
        {{ $t('schedule.simulation.title') }}
        <schedule-button
          v-if="!isYKSchedule"
          :is-disabled="isProcessingProductionProgress"
          type="secondary"
          @click="syncProductionProgress"
        >
          {{ $t('schedule.rolling.syncProductionProgress') }}
        </schedule-button>
      </h2>
      <plan-simulation />
    </div>
    <simulating-dialog
      v-if="isSimulatingDialogOpen"
      @cancel="cancelSimulation"
    />
    <schedule-decide-dialog
      v-if="isShowChangeAlert"
      :title="$t('schedule.simulation.changeAlert')"
      :content="$t('schedule.simulation.changeAlertContent')"
      :type="'showCancel'"
      :btn-text="$t('schedule.button.confirm')"
      @closeDialog="isShowChangeAlert = false"
      @confirmBtn="onConfirmResetScheduledJobs"
    />
  </div>
</template>
<script>
import ScheduleDecideDialog from '@/schedule/components/ScheduleDecideDialog'
import ScheduleTab from '@/schedule/components/ScheduleTab'
import BaseSetting from '../../scheduleSetting/BaseSetting'
import SplitMergeSetting from '@/schedule/pages/scheduleSetting/components/splitMergeSetting/SplitMergeSetting'
import PlanSimulation from './components/PlanSimulation'
import SimulatingDialog from './components/SimulatingDialog'
import SolutionCard from '../components/SolutionCard'
import { fetchDataBoundStatus } from '@/schedule/API/Project'
import { getJobList } from '@/schedule/API/Job'
import { mapState, mapMutations, mapGetters } from 'vuex'
import { getBaseSettingErrorMessages } from '@/schedule/utils/settingValidator'
import { Message } from 'element-ui'
import { solutionTemplate } from '@/schedule/types/simulation'
import { DATA_CATEGORY, DATAFRAME_STATUS } from '../../../utils/enum'
import { getIndexOfSpecificColumn } from '@/schedule/utils/utils'
import { DEFAULT_JOB_TABLE_COLUMN } from '@/schedule/utils/enum'
import moment from 'moment'

export default {
  name: 'SimulationSetting',
  components: {
    BaseSetting,
    SplitMergeSetting,
    ScheduleDecideDialog,
    ScheduleTab,
    PlanSimulation,
    SimulatingDialog,
    SolutionCard
  },
  provide () {
    return {
      $solutionInfo: () => this.solutions.find(solution => solution.sequence === this.editSolutionSequence),
      $solutionSequence: () => this.editSolutionSequence
    }
  },
  data () {
    return {
      isFetchingDefaultSetting: true,
      isFetchingSelectedOrders: false,
      isShowChangeAlert: false,
      isProcessingProductionProgress: false,
      allowSimulation: false,
      editSolutionSequence: null,
      solutionSerialNumber: 0,
      isSimulatingDialogOpen: false,
      defaultSolutionSetting: {},
      activeSettingTab: 'ScheduleBaseSetting'
    }
  },
  computed: {
    ...mapState('scheduleSetting', ['scheduleProjectId', 'defaultSetting']),
    ...mapState('simulation', ['solutions', 'planId', 'scheduledJobs', 'selectAllOrders', 'searchOrderCount', 'simulationJobs']),
    ...mapGetters('scheduleSetting', ['isYKSchedule']),
    selectedJobCount () {
      return Object.values(this.simulationJobs).filter(job => job.frontendInfo.checked).length
    },
    displaySelectedJobCounter () {
      if (this.isYKSchedule) {
        if (this.selectAllOrders) {
          return this.$t('schedule.simulation.selectedJobsCount', { count: this.searchOrderCount, job: this.isYKSchedule ? this.$t('schedule.simulation.order') : this.$t('schedule.simulation.job') })
        } else if (this.scheduledJobs.length > 0) {
          return this.$t('schedule.simulation.selectedJobsCount', { count: this.scheduledJobs.length, job: this.isYKSchedule ? this.$t('schedule.simulation.order') : this.$t('schedule.simulation.job') })
        } else {
          return this.$t('schedule.simulation.noSelectedJobs', { job: this.isYKSchedule ? this.$t('schedule.simulation.order') : this.$t('schedule.simulation.job') })
        }
      }
      return this.selectedJobCount > 0
        ? this.$t('schedule.simulation.selectedJobsCount', { count: this.selectedJobCount, job: this.$t('schedule.simulation.job') })
        : this.$t('schedule.simulation.noSelectedJobs', { job: this.$t('schedule.simulation.job') })
    },
    isStartSimulationButtonDisabled () {
      return !this.allowSimulation ||
      this.solutions.length === 0 ||
      (this.selectedJobCount === 0 && !this.isYKSchedule) ||
      (this.scheduledJobs.length === 0 && !this.selectAllOrders && this.isYKSchedule)
    },
    // TODO 之後改由 Profile 讀取
    getIndexOfColumnPriority () {
      return this.getIndexOfSpecificColumn(DEFAULT_JOB_TABLE_COLUMN.PRIORITY.toUpperCase())
    },
    settingTabs () {
      return [
        { type: 'ScheduleBaseSetting', name: this.$t('schedule.setting.title') },
        ...this.isYKSchedule ? [] : [
          { type: 'ScheduleSplitMergeSetting', name: this.$t('schedule.splitMergeSetting.name') }
        ]
      ]
    }
  },
  mounted () {
    if (Object.values(this.simulationJobs).length === 0) this.initSelectedJobCount()
    this.fetchDefaultSolutionSetting()
    this.fetchDataBoundStatus()
    this.solutions.forEach(solution => {
      if (solution.sequence > this.solutionSerialNumber) this.solutionSerialNumber = solution.sequence
      // 返回設定or重新模擬時，先做一次表單檢查以初始化 valid 狀態
      solution.errorMessages = getBaseSettingErrorMessages(solution)
    })
    this.editSolutionSequence = this.solutionSerialNumber
  },
  methods: {
    ...mapMutations('simulation', ['addSolution', 'removeSolution']),
    initSelectedJobCount () {
      // 拿到所有已選工單資訊
      this.isFetchingSelectedOrders = true
      getJobList({ projectId: this.scheduleProjectId, scheduled: true, fetchAll: true })
        .then(({ jobs }) => {
          this.$store.commit('simulation/setSimulationJobs', jobs.map(job => ({
            ...job,
            priority: job.fullData[this.getIndexOfColumnPriority],
            frontendInfo: { checked: true }
          })))
        })
        .catch(() => {})
        .finally(() => {
          this.isFetchingSelectedOrders = false
        })
    },
    fetchDefaultSolutionSetting () {
      this.$store.dispatch('scheduleSetting/getDefaultSetting')
        .then(([baseSetting, splitMergeSetting, operationSplitMergeRules, productSplitMergeBlacklist]) => {
          const formattedWorktimes = {}
          if (this.isYKSchedule) {
            this.$store.commit('scheduleSetting/updateSetting', baseSetting)
            this.isFetchingDefaultSetting = false
            return
          } else {
            // NOTICE 邏輯重複，在 sp2 已整進 actions 中
            // 不是鈺齊環境，要把 worktimes 格式統一成新版（含 hours, ranges）
            for (const [weekday, shifts] of Object.entries(baseSetting.worktimes)) {
              formattedWorktimes[weekday] = {
                hours: 0,
                ranges: shifts
              }
            }
          }
          this.defaultSolutionSetting = {
            ...baseSetting,
            worktimes: this.isYKSchedule ? baseSetting.worktimes : formattedWorktimes,
            splitMergeSetting: {
              splitMergeType: splitMergeSetting.splitMergeType,
              splitThreshold: splitMergeSetting.productSplitThreshold,
              mergeThreshold: splitMergeSetting.productMergeThreshold,
              operationSplitMergeRules,
              productSplitMergeBlacklist
            }
          }
          this.$store.commit('scheduleSetting/updateSetting', this.defaultSolutionSetting)
        })
        .catch(() => {})
        .finally(() => {
          this.isFetchingDefaultSetting = false
        })
    },
    fetchDataBoundStatus () {
      fetchDataBoundStatus(this.scheduleProjectId)
        .then(dataframes => {
          // 確定訂單、共同資料都已經綁定，不然不能進行模擬
          this.allowSimulation = dataframes
            .filter(item => item.category === DATA_CATEGORY.ORDER || item.category === DATA_CATEGORY.JOB || item.category === DATA_CATEGORY.RAW_DATA)
            .every(item => item.dataframeStatus === DATAFRAME_STATUS.BOUND)
          if (!this.allowSimulation) {
            Message({
              message: this.$t('schedule.simulation.notAllowedSimulation'),
              type: 'warning',
              duration: 3 * 1000,
              showClose: true
            })
          }
        })
    },
    isSolutionFocus (id) {
      return this.editSolutionSequence === id
    },
    onClickSelectJobStep () {
      if (this.planId) {
        this.isShowChangeAlert = true
      } else {
        this.editSolutionSequence = null
      }
    },
    onConfirmResetScheduledJobs () {
      this.$store.commit('simulation/setPlanId', null)
      this.$store.commit('simulation/setSolutions', this.solutions.map(solution => ({ ...solution, simulated: false })))
      this.editSolutionSequence = null
      this.isShowChangeAlert = false
    },
    onClickAddSolution () {
      this.solutionSerialNumber += 1
      this.editSolutionSequence = this.solutionSerialNumber

      this.addSolution({
        ...JSON.parse(JSON.stringify(this.defaultSetting)),
        ...solutionTemplate,
        sequence: this.solutionSerialNumber,
        scheduleStartDate: moment().startOf('day').add(1, 'day').format('YYYY/MM/DD'),
        projectId: this.scheduleProjectId,
        valid: true // YK 向下兼容
      })
    },
    onSwitchTab (activeSettingTab) {
      this.activeSettingTab = activeSettingTab
    },
    async onClickRemoveSolution (index, solutionId) {
      // 若為已經模擬過的方案，也要告訴後端把它刪掉
      if (this.planId && solutionId) {
        await this.$store.dispatch('simulation/deleteSimulatedSolution', solutionId)
      }

      // 若刪除自己，預設 focus 到第一個方案
      const isRemoveSelf = this.editSolutionSequence === this.solutions[index].sequence
      if (isRemoveSelf) {
        this.removeSolution(index)
        this.editSolutionSequence = this.solutions.length > 0 ? this.solutions[0].solutionId : null
      } else {
        this.removeSolution(index)
      }
      Message({
        message: this.$t('schedule.successMessage.solutionDeleted'),
        type: 'success',
        duration: 3 * 1000,
        showClose: true
      })
    },
    editSolution (solution) {
      this.editSolutionSequence = solution.sequence
      this.activeSettingTab = 'ScheduleBaseSetting'
    },
    validateSolutions () {
      // 模擬方案：進行前端表單驗證
      let allSolutionValid = true
      this.solutions.forEach(solution => {
        const errorMessages = getBaseSettingErrorMessages(solution)
        if (errorMessages.length > 0) {
          this.$store.commit('simulation/updateSolutionErrorMessagesBySequence', {
            sequence: solution.sequence,
            errorMessages
          })
          allSolutionValid = false
        }
      })

      // 全部方案通過，開始模擬
      if (allSolutionValid) {
        this.startSimulation()
      } else {
        // 部分方案檢查有誤，focus 到第一個有誤的方案上
        const findFirstInvalidSolution = this.solutions.find(solution => solution.errorMessages.length > 0)
        this.editSolutionSequence = findFirstInvalidSolution.sequence
        Message({
          message: this.$t('schedule.errorMessage.invalidSolutionSetting'),
          type: 'warning',
          duration: 3 * 1000,
          showClose: true
        })
      }
    },
    startSimulation () {
      this.isSimulatingDialogOpen = true
    },
    cancelSimulation () {
      this.$store.commit('simulation/setPlanId', null)
      this.isSimulatingDialogOpen = false
    },
    isSolutionFailed (solutionId) {
      return this.$store.state.simulation.simulationResult.failedSolutionIds.includes(solutionId)
    },
    syncProductionProgress () {
      this.isProcessingProductionProgress = true
      this.$store.dispatch('scheduleSetting/syncProductionProgress')
        .then(() => {
          Message({
            message: this.$t('schedule.successMessage.syncProductionProgressSuccessfully'),
            type: 'success',
            duration: 3 * 1000,
            showClose: true
          })
        })
        .catch(() => {})
        .finally(() => {
          this.isProcessingProductionProgress = false
        })
    },
    getIndexOfSpecificColumn
  }
}
</script>

<style lang="scss" scoped>
.page {
  &--simulation {
    display: flex;
    height: 100%;
  }

  ::v-deep &.page--setting {
    height: 100%;
    overflow: auto;
    padding: 0;
  }

  &__side-nav {
    display: flex;
    flex-basis: 267px;
    flex-direction: column;
  }

  &__main {
    border-left: 1px solid var(--color-border);
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: auto;
    overflow: overlay;
    position: relative;
    width: 0;

    .header__title {
      display: flex;
      font-size: 24px;
      justify-content: space-between;
      line-height: 32px;
      margin-bottom: 0;
      margin-top: 0;
      padding: 24px 24px 0;
    }

    &--switcher {
      background-color: rgb(16, 25, 25);
      position: sticky;
      top: 0;
      z-index: 2;
    }
  }

  &__spinner {
    margin-top: 30vh;
  }
}

.step {
  .step__title {
    font-size: 14px;
  }

  .step__content {
    margin-top: 12px;
  }

  &--choose-job {
    padding: 24px;

    .step__content {
      text-indent: 16px;

      .selected-jobs__info {
        align-items: center;
        background-color: var(--color-bg-gray);
        border-radius: 10px;
        cursor: pointer;
        display: flex;
        font-size: 14px;
        height: 52px;
      }
    }
  }

  &--choose-solution {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 0;
    padding: 24px 24px 0;

    .step__title {
      margin-bottom: 8px;

      .add-btn {
        border-radius: 6px;
        margin-top: 12px;
        width: 100%;
      }
    }

    .step__content {
      flex: 1;
      overflow-y: overlay;

      .solution {
        &__checked {
          left: 16px;
          position: absolute;
          top: 14px;
        }

        &__status {
          color: var(--color-text-gray);
          font-size: 14px;
        }
      }
    }
  }

  &--start-simulate {
    background-color: rgba(35, 61, 64, 0.6);

    .default-button {
      border-radius: 0;
      height: 48px;
      width: 100%;
    }
  }
}
</style>
