<template>
  <div
    v-if="isYKSchedule"
    class="plan-simulation"
  >
    <unscheduled-jobs-table
      class="unscheduled-jobs-table"
    />
    <hr>
    <scheduled-jobs-table
      v-show="!selectAllOrders"
      class="scheduled-jobs-table"
    />
  </div>
  <div
    v-else
    class="plan-simulation"
  >
    <jobs-header>
      <jobs-filter
        slot="filter"
        :job-states-options="jobStates"
        @submit="updateRestrictions"
      />
    </jobs-header>
    <jobs-table :restrictions="restrictions" />
  </div>
</template>

<script>
import JobsFilter from '@/schedule/components/JobsFilter'
import JobsTable from './JobsTable'
import JobsHeader from './JobsHeader'
import UnscheduledJobsTable from './UnscheduledJobsTable'
import ScheduledJobsTable from './ScheduledJobsTable'
import { JOB_STATUS } from '@/schedule/utils/enum'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'PlanSimulation',
  components: {
    JobsFilter,
    JobsTable,
    JobsHeader,
    UnscheduledJobsTable,
    ScheduledJobsTable
  },
  data () {
    return {
      restrictions: {}
    }
  },
  computed: {
    ...mapState('simulation', ['selectAllOrders']),
    ...mapState('scheduleSetting', ['globalJobStatusRestriction']),
    ...mapGetters('scheduleSetting', ['isYKSchedule']),
    jobStates () {
      return [
        JOB_STATUS.SCHEDULED,
        JOB_STATUS.DELAY,
        JOB_STATUS.OVERDUE,
        JOB_STATUS.OVERDUE_DELAY,
        JOB_STATUS.UNSCHEDULED
      ]
    }
  },
  created () {
    if (this.globalJobStatusRestriction) {
      this.restrictions[this.globalJobStatusRestriction] = true
    }
  },
  methods: {
    updateRestrictions (newVal) {
      this.restrictions = newVal
    }
  }
}
</script>

<style lang="scss" scoped>
.plan-simulation {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 24px;

  .unscheduled-jobs-table {
    flex: 1;
    margin-bottom: 24px;
  }

  .scheduled-jobs-table {
    flex: 1;
  }

  hr {
    border-color: var(--color-bg-gray);
    margin-block-end: 24px;
    margin-block-start: 0;
  }
}
</style>
